import gql from 'graphql-tag';

export const ContentTitlePublicLinkInformationQuery = gql`
	query ContentTitlePublicLinkInformationQuery($publicLinkId: ID!) {
		publicLinkInformation(id: $publicLinkId) {
			id
			title
		}
	}
`;
